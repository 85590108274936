import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { CollapsibleButton } from "../SideBarV2/CollapsibleButton";

// Helper function to recursively generate routes
export const genNav: any = (
  routes: any,
  pathname: string,
  workspaceId: string,
  role: string
) => {
  return Object.keys(routes).map((key, index) => {
    const route = routes[key];

    // Check if there are nested routes
    if (
      route.routes &&
      Object.keys(route.routes).length > 0 &&
      (route?.access?.includes(role) ||
        route?.access?.length === 0 ||
        route?.access === undefined)
    ) {
      return (
        <>
          {route.nav ? (
            <CollapsibleButton
              label={route.name}
              icon={route.icon}
              level={route.level}
            >
              {genNav(route.routes, pathname, workspaceId, role)}
            </CollapsibleButton>
          ) : (
            genNav(route.routes, pathname, workspaceId, role)
          )}
        </>
      );
    }

    if (
      route?.access?.includes(role) ||
      route?.access?.length === 0 ||
      route?.access === undefined
    ) {
      return (
        route.nav && (
          <>
            <SidenavButtonLink
              active={pathname.includes(route.key)}
              to={route.path.replace(":workspaceId", workspaceId)}
              className={pathname.includes(route.key) ? "active" : ""}
              icon={route.icon}
              label={route.name}
            />
          </>
        )
      );
    } else return null;
  });

  // Base case: no nested routes
};
