import React, { lazy, Suspense, useEffect } from "react";
import map from "lodash/map";
import { Navigate, Route, Routes } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import DescriptionIcon from "@mui/icons-material/Description";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TuneIcon from "@mui/icons-material/Tune";
import { stubFalse } from "lodash";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import SupportIcon from "@mui/icons-material/Support";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

import { BrightBotPage } from "../BrightBot/BrightBotPage";
import { ProjectsListPage } from "../Projects/ProjectsListPage/ProjectsListPage";
import { ProjectOverviewPage } from "../Projects/ProjectOverviewPage/ProjectOverviewPage";
import WorkspaceSwitcherPage from "../WorkspaceSwitcher/WorkspaceSwitcherPage";
import { ProjectWorkflowPage } from "../ProjectWorkflow/ProjectWorkflowPage";
import { CreatedDataProductsPage } from "../Projects/CreatedDataProductsPage/CreatedDataProductsPage";
import { ProjectDataAssetPage } from "../Projects/ProjectDataAssetPage/ProjectDataAssetPage";
import { MyDataAssetPage } from "../Projects/MyDataAssetPage/MyDataAssetPage";
import { ProjectSchemaPage } from "../Projects/ProjectSchemaPage/ProjectSchemaPage";
import { ProjectSchemaEditDetailPage } from "../Projects/ProjectSchemaPage/ProjectSchemaDetailsPage";
import { DataAssetCatalogPage } from "../DataAssetCatalog/DataAssetCatalogPage/DataAssetCatalogPage";
import { AssetDetail } from "../AssetDetail/AssetDetail";
import { AssetDetailPage } from "../AssetDetail/AssetDetailPage/AssetDetailPage";
import { MembersPage } from "../Members/MembersPage/MembersPage";
import { All } from "../Sources/All/All";
import { Transformation } from "../DataStackServices/Transformation/Transformation";
import { Warehouse } from "../DataStackServices/Warehouse/Warehouse";
import { Connection } from "../DataStackServices/Connection/Connection";
import { GovernancePage } from "../Governance/GovernancePage";
import { GovernancePolicyDetailPage } from "../Governance/GovernancePolicyDetailPage";
import { MyOrganization } from "../MyOrganization/MyOrganization";
import { MyOrganizationPage } from "../MyOrganization/MyOrganizationPage";
import { MyProfilePage } from "../ContentPages/MyProfilePage";
import { WorkspaceConfigurationPage } from "../WorkspaceConfiguration/WorkspaceConfigurationPage";
import { WorkspaceSettingsPage } from "../WorkspaceSettings";
import { Resources } from "../KnowledgeCenter/Resources/Resources";
import { Schemas } from "../KnowledgeCenter/Schema";
import { SchemaCompare } from "../KnowledgeCenter/Schema/SchemaCompare/SchemaCompare";
import { SchemaDetailPage } from "../KnowledgeCenter/Schema/SchemaDetail/SchemaDetailPage";
import { Terms } from "../KnowledgeCenter/Terms";
import { Login } from "../Auth/Login";
import { ForgotPassword } from "../Auth/ForgotPassword";
import { ResetPassword } from "../Auth/ResetPassword";
import { Unauthorized } from "../ErrorPage/Unauthorized";
import { WorkspaceRoleEnum } from "../generated";
import { ChatBotIcon } from "../common/Icons/ChatBotIcon";

import { BrightBotViiIcon } from "../common/Icons/BrightBotViiIcon";
import { NavConnectViiIcon } from "../common/Icons/NavConnectViiIcon";
import { NavGovernViiIcon } from "../common/Icons/NavGovernViiIcon";
import { NavBuildViiIcon } from "../common/Icons/NavBuildViiIcon";
import { NavShareViiIcon } from "../common/Icons/NavShareViiIcon";
import { SourcesViiIcon } from "../common/Icons/SourcesViiIcon";
import { LayoutGridViiIcon } from "../common/Icons/LayoutGridViiIcon";
import { TablerListSearchViiIcon } from "../common/Icons/TablerListSearchViiIcon";
import { DataAssetLibraryIcon } from "../common/Icons/DataAssetLibraryIcon";
import { GovernanceIcon } from "../common/Icons/GovernanceIcon";
import { ProjectsFolderViiIcon } from "../common/Icons/ProjectsFolderViiIcon";
import { HomeViiIcon } from "../common/Icons/HomeViiIcon";
import ProjectOverviewForm from "../Projects/ProjectOverviewPage/ProjectOverviewForm";
import { RegisterUser } from "../Auth/RegisterFlow/RegisterUser/RegisterUser";
import { PolicyAgreement } from "../Auth/RegisterFlow/PolicyAgreement/PolicyAgreement";

export enum NavLevel {
  root = "root",
  first = "first-level",
  second = "second-level",
  third = "third-level",
  fourth = "fourth-level",
}

const hive = {
  name: "TheHive",
  path: `/workspace/:workspaceId/the-hive`,
  icon: <TablerListSearchViiIcon />,
  nav: true,
  level: NavLevel.second,
  component: Resources,
  routes: {
    Resources: {
      name: "Resources",
      path: `/workspace/:workspaceId/the-hive/resources`,
      icon: <GroupIcon />,
      nav: true,
      key: "resources",
      level: NavLevel.third,
      component: Resources,
    },
    ResourcesFolder: {
      name: "Resources",
      path: `/workspace/:workspaceId/the-hive/resources/:folderId`,
      key: "resources",
      icon: <GroupIcon />,
      level: NavLevel.third,
      component: Resources,
    },
    Terms: {
      name: "Terms",
      path: `/workspace/:workspaceId/the-hive/terms`,
      icon: <GroupIcon />,
      nav: true,
      key: "terms",
      component: Terms,
      level: NavLevel.third,
    },
    Schemas: {
      name: "Schemas",
      path: `/workspace/:workspaceId/the-hive/schemas`,
      icon: <GroupIcon />,
      level: NavLevel.third,
      routes: {
        Schema: {
          name: "Schemas",
          path: `/workspace/:workspaceId/the-hive/schemas`,
          icon: <GroupIcon />,
          nav: true,
          key: "schemas",
          level: NavLevel.fourth,
          component: Schemas,
        },
        SchemaCompare: {
          name: "Schema Compare",
          path: `/workspace/:workspaceId/the-hive/schemas/compare`,
          icon: <GroupIcon />,
          level: NavLevel.fourth,
          component: SchemaCompare,
        },
        SchemaDetailPage: {
          name: "Schema Detail Page",
          path: `/workspace/:workspaceId/the-hive/schemas/:schemaId`,
          icon: <GroupIcon />,
          level: NavLevel.fourth,
          component: SchemaDetailPage,
        },
      },
    },
  },
};

export const AssetRoutes = {
  Workspace: {
    name: "Workspace",
    path: `/workspace`,
    icon: <GroupIcon />,
    level: NavLevel.root,
    component: WorkspaceSwitcherPage,
    routes: {
      HomePage: {
        name: "Home",
        icon: <HomeViiIcon />,
        path: `/workspace/:workspaceId/project`,
        nav: true,
        key: "project",
        level: NavLevel.first,
        component: ProjectsListPage,
      },
      BrightBotPage: {
        name: "BrightBot",
        path: `/workspace/:workspaceId/brightbot`,
        icon: <BrightBotViiIcon />,
        nav: true,
        key: "brightbot",
        component: BrightBotPage,
        level: NavLevel.first,
      },
      Connect: {
        name: "Connect",
        icon: <NavConnectViiIcon />,
        nav: true,
        level: NavLevel.first,
        access: [WorkspaceRoleEnum.Admin],
        routes: {
          Sources: {
            name: "Sources",
            path: `/workspace/:workspaceId/sources`,
            icon: <SourcesViiIcon />,
            nav: true,
            level: NavLevel.second,
            component: All,
            routes: {
              Sour: {
                name: "All",
                path: `/workspace/:workspaceId/sources`,
                nav: false,
                component: All,
                key: "all",
                level: NavLevel.third,
              },
              All: {
                name: "All",
                path: `/workspace/:workspaceId/sources/all`,
                nav: true,
                component: All,
                key: "all",
                level: NavLevel.third,
              },
              Internal: {
                name: "Internal",
                path: `/workspace/:workspaceId/sources/internal`,
                nav: true,
                key: "internal",
                component: All,
                level: NavLevel.third,
              },
            },
          },
          Services: {
            name: "Services",
            path: `/workspace/:workspaceId/services`,
            icon: <LayoutGridViiIcon />,
            nav: true,
            component: Connection,
            level: NavLevel.second,
            routes: {
              Connections: {
                name: "Connections",
                path: `/workspace/:workspaceId/services/connection`,
                nav: true,
                key: "connection",
                component: Connection,
                level: NavLevel.third,
              },
              Warehouse: {
                name: "Warehouse",
                path: `/workspace/:workspaceId/services/warehouse`,
                nav: true,
                key: "warehouse",
                component: Warehouse,
                level: NavLevel.third,
              },
              Transformation: {
                name: "Transformation",
                path: `/workspace/:workspaceId/services/transformation`,
                nav: true,
                key: "transformation",
                component: Transformation,
                level: NavLevel.third,
              },
            },
          },
        },
      },
      Govern: {
        name: "Govern",
        icon: <NavGovernViiIcon />,
        nav: true,
        level: NavLevel.first,
        access: [
          WorkspaceRoleEnum.Admin,
          WorkspaceRoleEnum.Collaborator,
          WorkspaceRoleEnum.Viewer,
        ],
        routes: {
          DataAsset: {
            name: "Data Assets",
            path: `/workspace/:workspaceId/data`,
            nav: true,
            key: "data",
            icon: <DataAssetLibraryIcon />,
            component: DataAssetCatalogPage,
            level: NavLevel.second,
            access: [WorkspaceRoleEnum.Admin, WorkspaceRoleEnum.Collaborator],
          },
          TheHive: hive,
          Policies: {
            name: "Policies",
            path: `/workspace/:workspaceId/governance`,
            icon: <GovernanceIcon />,
            key: "governance",
            nav: true,
            level: NavLevel.second,
            component: GovernancePage,
          },
        },
      },
      Build: {
        name: "Build",
        icon: <NavBuildViiIcon />,
        nav: true,
        level: NavLevel.first,
        routes: {
          Projects: {
            name: "Projects",
            path: `/workspace/:workspaceId/project`,
            icon: <ProjectsFolderViiIcon />,
            nav: false,
            level: NavLevel.second,
            routes: {
              ProjectsListPage: {
                name: "Projects",
                path: `/workspace/:workspaceId/project`,
                icon: <ProjectsFolderViiIcon />,
                nav: true,
                key: "project",
                level: NavLevel.third,
                component: ProjectsListPage,
              },
              ProjectOverview: {
                name: "Project Overview",
                path: `/workspace/:workspaceId/project/:projectId/project-overview`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: ProjectOverviewPage,
              },
              ProjectOverviewEdit: {
                name: "Project Overview",
                path: `/workspace/:workspaceId/project/:projectId/project-overview/edit`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                access: [WorkspaceRoleEnum.Admin],
                component: ProjectOverviewPage,
              },
              ProjectWorkflow: {
                name: "Project Workflow Page",
                path: `/workspace/:workspaceId/project/:projectId/project-workflow`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: ProjectWorkflowPage,
                access: [
                  WorkspaceRoleEnum.Admin,
                  WorkspaceRoleEnum.Collaborator,
                ],
              },
              CreatedDataProducts: {
                name: "Created Data Products Page",
                path: `/workspace/:workspaceId/project/:projectId/created-data-products`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: CreatedDataProductsPage,
              },
              ProjectDataAsset: {
                name: "ProjectDataAsset",
                path: `/workspace/:workspaceId/project/:projectId/data-assets`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                component: ProjectDataAssetPage || MyDataAssetPage,
              },
              ProjectSchema: {
                name: "Project Schema",
                path: `/workspace/:workspaceId/project/:projectId/project-schema`,
                icon: <DonutSmallIcon />,
                nav: false,
                level: NavLevel.third,
                routes: {
                  ProjectSchemaPage: {
                    name: "Project Schema Detail Page",
                    path: `/workspace/:workspaceId/project/:projectId/project-schema`,
                    icon: <DonutSmallIcon />,
                    nav: false,
                    level: NavLevel.fourth,
                    component: ProjectSchemaPage,
                  },
                  ProjectSchemaDetail: {
                    name: "Project Schema Detail Page",
                    path: `/workspace/:workspaceId/project/:projectId/project-schema/:schemaId`,
                    icon: <DonutSmallIcon />,
                    nav: false,
                    level: NavLevel.fourth,
                    component: ProjectSchemaEditDetailPage,
                  },
                },
              },
            },
          },
        },
      },
      DataAssetDetailPage: {
        name: "Data Asset Detail Page",
        path: `/workspace/:workspaceId/asset-details/:dataAssetId`,
        icon: <GroupIcon />,
        component: AssetDetailPage,
        level: NavLevel.first,
      },
      Members: {
        name: "Members Page",
        path: `/workspace/:workspaceId/members`,
        icon: <GroupIcon />,
        nav: false,
        component: MembersPage,
        level: NavLevel.first,
      },
      GovernancePolicyDetail: {
        name: "Governance Policy Detail Page",
        path: `/workspace/:workspaceId/governance/detail-id`,
        icon: <GroupIcon />,
        nav: false,
        component: GovernancePolicyDetailPage,
        level: NavLevel.first,
      },
      MyOrganization: {
        name: "My Organization Page",
        path: `/workspace/:workspaceId/my-organization`,
        icon: <GroupIcon />,
        nav: false,
        component: MyOrganizationPage,
        level: NavLevel.first,
      },
      MyProfile: {
        name: "My Profile Page",
        path: `/workspace/:workspaceId/my-profile`,
        icon: <GroupIcon />,
        nav: false,
        component: MyProfilePage,
        level: NavLevel.first,
      },
      WorkspaceConfiguration: {
        name: "Workspace Configuration Page",
        path: `/workspace/:workspaceId/workspace-configuration`,
        icon: <GroupIcon />,
        nav: false,
        component: WorkspaceConfigurationPage,
        level: NavLevel.first,
      },
      WorkspaceSettings: {
        name: "Workspace Settings Page",
        path: `/workspace/:workspaceId/workspace-settings`,
        icon: <GroupIcon />,
        nav: false,
        component: WorkspaceSettingsPage,
        access: [WorkspaceRoleEnum.Admin],
        level: NavLevel.first,
      },
    },
  },
};

export const LoginRoutes = {
  LOGIN: {
    name: "Login",
    path: `/login`,
    icon: <GroupIcon />,
    level: NavLevel.root,
    routes: {
      Login: {
        name: "Login",
        path: `/login`,
        icon: <GroupIcon />,
        component: Login,
        level: NavLevel.first,
      },
      ForgotPassword: {
        name: "ForgotPassword",
        path: `/login/forgotPassword`,
        icon: <GroupIcon />,
        level: NavLevel.first,
        component: ForgotPassword,
      },
      ResetPassword: {
        name: "ForgotPassword",
        path: `/login/resetPassword`,
        icon: <GroupIcon />,
        level: NavLevel.first,
        component: ResetPassword,
      },
    },
  },
  RegisterUserPage: {
    name: "Register User Page",
    path: `/register-user/:username/:session`,
    icon: <GroupIcon />,
    component: RegisterUser,
    level: NavLevel.root,
  },
  PolicyAgreement: {
    name: "Policy Agreement Page",
    path: `/agree-policy/?:workspaceId`,
    icon: <GroupIcon />,
    component: PolicyAgreement,
    level: NavLevel.root,
  },
  404: {
    name: "404",
    path: "/404",
    icon: <GroupIcon />,
    level: NavLevel.root,
    component: Unauthorized,
  },
};
