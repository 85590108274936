import { useTheme } from "@mui/material";
import { BrightBotSmileIcon } from "../../common/Icons/BrightBotSmileIcon";
import { ChatBotHeaderHeading, ChatBotHeaderIcon, ChatBotHeaderWrapper } from "../style";

export const ChatBotHeader = () => {
  const { palette } = useTheme();

  return (
    <ChatBotHeaderWrapper className="ChatBotHeader">
      <ChatBotHeaderIcon>
        <BrightBotSmileIcon />
      </ChatBotHeaderIcon>
      <ChatBotHeaderHeading variant="h1">BrightBot</ChatBotHeaderHeading>
    </ChatBotHeaderWrapper>
  );
};
