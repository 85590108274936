import React, { createContext, useState } from "react";
import { LicenseManager } from "ag-grid-enterprise";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AssetDetailPage } from "./AssetDetail/AssetDetailPage/AssetDetailPage";
import { ConfirmOrganization } from "./Auth/RegisterFlow/ConfirmOrganization/ConfirmOrganization";
import { DataAssetCatalogPage } from "./DataAssetCatalog/DataAssetCatalogPage/DataAssetCatalogPage";
import { GovernancePage } from "./Governance/GovernancePage";
import { GovernancePolicyDetailPage } from "./Governance/GovernancePolicyDetailPage";
import { Login } from "./Auth/Login";
import { ForgotPassword } from "./Auth/ForgotPassword";
import { MembersPage } from "./Members/MembersPage/MembersPage";
import { MyOrganizationPage } from "./MyOrganization/MyOrganizationPage";
import { MyProfilePage } from "./ContentPages/MyProfilePage";
import { PolicyAgreement } from "./Auth/RegisterFlow/PolicyAgreement/PolicyAgreement";
import { ProjectsListPage } from "./Projects/ProjectsListPage/ProjectsListPage";
import { ProtectedRoute } from "./Auth/ProtectedRoute";
import { RegisterUser } from "./Auth/RegisterFlow/RegisterUser/RegisterUser";
import { ProjectWorkflowPage } from "./ProjectWorkflow/ProjectWorkflowPage";
import { ProjectOverviewPage } from "./Projects/ProjectOverviewPage/ProjectOverviewPage";
import { ProjectSchemaPage } from "./Projects/ProjectSchemaPage/ProjectSchemaPage";
import { ProjectDataAssetPage } from "./Projects/ProjectDataAssetPage/ProjectDataAssetPage";
import { CreatedDataProductsPage } from "./Projects/CreatedDataProductsPage/CreatedDataProductsPage";
import { ProjectSchemaDetailPage } from "./Projects/ProjectSchemaDetailPage/ProjectSchemaDetailPage";
import { WorkspaceConfigurationPage } from "./WorkspaceConfiguration/WorkspaceConfigurationPage";
import { ProjectSchemaEditDetailPage } from "./Projects/ProjectSchemaPage/ProjectSchemaDetailsPage";
import { ProtectedNav } from "./Auth/ProtectedNav";
import { MyDataAssetPage } from "./Projects/MyDataAssetPage/MyDataAssetPage";
import { useGetUserRole } from "./hooks/useGetUserRole";
import { useWorkspaceId } from "./hooks/useWorkspace";
import { ResetPassword } from "./Auth/ResetPassword";
import WorkspaceSwitcherPage from "./WorkspaceSwitcher/WorkspaceSwitcherPage";
import { Unauthorized } from "./ErrorPage/Unauthorized";
import { Transformation } from "./DataStackServices/Transformation/Transformation";
import { All } from "./Sources/All/All";
import { WorkspaceSettingsPage } from "./WorkspaceSettings";
import { Warehouse } from "./DataStackServices/Warehouse/Warehouse";
import { Connection } from "./DataStackServices/Connection/Connection";
import { WorkspaceRoleEnum } from "./generated";
import { BrightBotPage } from "./BrightBot/BrightBotPage";
import { KnowledgeCenter } from "./KnowledgeCenter/KnowledgeCenter";
import { Resources } from "./KnowledgeCenter/Resources/Resources";
import { Session } from "./Session/Session";
import { Terms } from "./KnowledgeCenter/Terms";
import { Schemas } from "./KnowledgeCenter/Schema";
import { SchemaDetailPage } from "./KnowledgeCenter/Schema/SchemaDetail/SchemaDetailPage";
import { SchemaCompare } from "./KnowledgeCenter/Schema/SchemaCompare/SchemaCompare";
import { AssetRoutes, LoginRoutes } from "./routes";
import { generateRoutes } from "./routes/genRoutes";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY || "");

interface AppContextType {
  showSidenav: boolean;
  setShowSidenav: (...args: any[]) => any;
}

export const AppContext = createContext<AppContextType>({
  showSidenav: true,
  setShowSidenav: () => {},
});

const setting = {
  color: {
    theme: "#000",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } },
    ],
  },
};

export const App = () => {
  const { workspaceId } = useWorkspaceId();
  const [showSidenav, setShowSidenav] = useState(true);
  const { accessible, role } = useGetUserRole(workspaceId);

  if (!role && workspaceId) return null;


  return (
    <AppContext.Provider value={{ showSidenav, setShowSidenav }}>
      <ToastContainer autoClose={3000} />
      <ProtectedNav>
        <Routes>
          <Route
            path="/workspace"
            element={
              <ProtectedRoute>
                <WorkspaceSwitcherPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agree-policy"
            element={
              <ProtectedRoute>
                <PolicyAgreement />
              </ProtectedRoute>
            }
          />
          {generateRoutes(AssetRoutes.Workspace.routes, true)}
          {generateRoutes(LoginRoutes)}
          <Route path="*" element={<Navigate to={"/workspace"} />} />
        </Routes>
      </ProtectedNav>
    </AppContext.Provider>
  );
};
