import { Box, useTheme } from "@mui/material";
import { CompareScoreBox } from "./style";

export const CompareScore = ({ data, className }: any) => {
  const { palette } = useTheme();
  const setCompareScoreBg = (score: any) => {
    let color: any = palette.customScores.main;
    console.log("-----", score);
    switch (parseInt(score)) {
      case 0:
        color = palette.customScores.dark;
        break;
      case 1:
        color = palette.customScores.dark;
        break;
      case 2:
        color = palette.customScores.light;
        break;
      case 3:
        color = palette.customScores.contrastText;
        break;
      case 4:
        color = palette.customScores.text;
        break;
      case 5:
        color = palette.customScores.main;
        break;
    }
    return color;
  };

  return (
    <CompareScoreBox
      className={className}
      sx={{ background: setCompareScoreBg(data) }}
    >
      {data ? data : data === 0 ? "0" : "-"}
    </CompareScoreBox>
  );
};
