import { useState } from "react";
import { Tab, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Wrapper, Tabset, TabItem, TopArea, BottomArea, TabsWrapper, TabButton } from "./style";
import { ChatBot } from "./ChatBox/ChatBot";
import { ChatBotHeader } from "./ChatBotHeader/ChatBotHeader";
import { ChatHistory } from "./ChatHistory/ChatHistory";

export const BrightBotPage = ({ query }: any) => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const tabParam = sp.get("tab");

  const [tab, setTab] = useState(tabParam === "chat" ? 0 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Wrapper className="BrightBotPage">
      <TopArea>
        <ChatBotHeader />
        <TabsWrapper
          value={tab}
          onChange={handleChange}
          aria-label="asset detail tab"
        >
          <TabButton label="Chat" {...a11yProps(0)} />
          {/* <TabItem label="History" disabled={true} {...a11yProps(1)} /> */}
        </TabsWrapper>
      </TopArea>
      <BottomArea>
        {tab === 0 && <ChatBot />}
        {/* {tab === 1 && <ChatHistory />} */}
      </BottomArea>
    </Wrapper>
  );
};
