import { useMemo } from "react";
import { Button } from "../../../common/Button/Button";
import { Box, List, ListItem, Typography } from "@mui/material";
import { CollapseSchema } from "./CollapseSchema";
import {
  ComparingListHolder,
  SchemaListItems,
  EmptyBox,
  SchemaName,
  SchemaType,
  NoRecordBlock,
} from "./style";
import { CompareScore } from "./CompareScore";
import { testSchemaOne } from "./TestData/schemaOne";
import { testSchemaTwo } from "./TestData/schemaTwoResponse";
import { responseCompare } from "./TestData/response";

export const SchemaList = ({ baseSchema, comparableSchema, compare }: any) => {
  const { newData1, newData2 } = useMemo(() => {
    let newData1: any = [];
    let newData2: any = [];

    // const property1 = Object.entries(testSchemaOne.properties);
    // const property2 = Object.entries(testSchemaTwo.properties);

    responseCompare.field_comparison.forEach((compare) => {
      newData1 = [
        ...newData1,
        // property1.find((p) => p[0] === compare.schema1_field),
      ];
      newData2 = [
        ...newData2,
        // property2.find((p) => p[0] === compare.schema2_field),
      ];
    });

    newData1 = newData1.filter(Boolean);
    newData2 = newData2.filter(Boolean);

    newData1 = [
      ...newData1,
      // ...property1.filter((p) => newData1.every((n: any) => n[0] !== p[0])),
    ];
    newData2 = [
      ...newData2,
      // ...property2.filter((p) => newData2.every((n: any) => n[0] !== p[0])),
    ];

    return { newData1, newData2 };
  }, [testSchemaOne, testSchemaTwo, responseCompare]);

  const len =
    newData1.length > newData2.length ? newData1.length : newData2.length;

  const scoreCal = (name1: any, name2: any) => {
    let score: any;

    responseCompare?.field_comparison?.forEach((compare) => {
      if (compare.schema1_field === name1 && compare.schema2_field === name2) {
        score = compare.comparison_score;
        return;
      }
    });

    return score;
  };

  const schemaList = (schema: any) => {
    if (schema) {
      const parse = JSON.parse(schema.jsonSchema);
      // const parse = schema;
      return Object.keys(parse.properties || {}).map((key, idx): any => {
        const item = parse.properties[key];
        let type = item.type;
        if (item.enum) {
          type = "enum";
        }

        const callRecords: any[] = [];

        item.anyOf?.forEach((element: any) => {
          let value = "";

          if (element.type === "string") {
            value = element.pattern;
          }

          if (element.enum) {
            value = element.enum.join(", ");
          }

          callRecords.push({
            ...element,
            type: element.enum ? "enum" : element.type,
            value,
          });
        });

        let value = "";

        if (item.type === "string") {
          value = item.pattern;
        }

        if (item.enum) {
          value = item.enum.join(", ");
        }

        return {
          type,
          value,
          no: idx + 1,
          name: key,
          callRecords,
          required: parse.required?.includes(key) || false,
          ...item,
        };
      });
    }
    return [];
  };

  return (
    <ComparingListHolder>
      {compare ? (
        compare.length > 1 ? (
          <SchemaListItems className="compare-response">
            {/* {JSON.stringify(baseSchema)} */}
            {/* {useSchemaList(baseSchema).map((row: any) => ( */}
            {[...Array(len)].map((x, index) => (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {newData1?.[index] && (
                  <ListItem>
                    <SchemaName>{newData1[index][0]}</SchemaName>
                    <SchemaType>{newData1[index][1].type}</SchemaType>
                  </ListItem>
                )}

                {compare && newData1?.[index] && newData2?.[index] && (
                  <CompareScore
                    className="custom-compare-box"
                    data={scoreCal(
                      newData1?.[index]?.[0],
                      newData2?.[index]?.[0]
                    )}
                  />
                )}

                {newData2?.[index] && (
                  <ListItem>
                    <SchemaName>{newData2[index][0]}</SchemaName>
                    <SchemaType>{newData2[index][1].type}</SchemaType>
                  </ListItem>
                )}
              </Box>
            ))}
          </SchemaListItems>
        ) : (
          <NoRecordBlock>
            <Typography mt={2} className="no-record" textAlign={"center"}>
              No Comparison Found
            </Typography>
          </NoRecordBlock>
        )
      ) : (
        <>
          <SchemaListItems>
            {schemaList(baseSchema).map((row: any) => (
              <ListItem>
                <SchemaName>{row?.name}</SchemaName>
                <SchemaType>{row?.type}</SchemaType>
              </ListItem>
            ))}
          </SchemaListItems>
          <EmptyBox />
          <SchemaListItems>
            {schemaList(comparableSchema).map((row: any) => (
              <ListItem>
                <SchemaName>{row?.name}</SchemaName>
                <SchemaType>{row?.type}</SchemaType>
              </ListItem>
            ))}
          </SchemaListItems>
        </>
      )}
    </ComparingListHolder>
  );
};
